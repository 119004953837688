import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const companyList = [];
for (let i = 1; i <= 12; i++) {
  companyList.push({ id: `${i}`, image: `/images/company/${i}.png` });
}

export default function Company() {
  const [ref, inView] = useInView({
    triggerOnce: true, // This ensures the animation triggers only once when the element comes into view
  });
  return (
    <section className="company">
      <div className="auto__container">
        <div className="company__inner">
          <div className="company__inner-title">
            <h3>YOU’RE IN GREAT COMPANY</h3>
            <p>
              Join an invitation-only group of peers including confirmed and
              invited executives from:
            </p>
          </div>
          <div className="company-row-alt">
            {companyList.map((item, index) => {
              // scale up elanacaring
              let imgClass = "";
              // if (index === 10) {
              //   imgClass = " elana-caring-img";
              // }
              return (
                <motion.div
                  key={index}
                  className="company-logo"
                  ref={ref}
                  initial={{ opacity: 0, scale: 0 }} // Set your initial state
                  animate={inView ? { opacity: 1, scale: 1 } : {}} // Set your animation properties
                  transition={{
                    duration: 0.9,
                    ease: "easeOut",
                    delay: index * 0.15,
                  }}
                >
                  <img
                    className={`img-fluid ${imgClass}`}
                    src={process.env.PUBLIC_URL + item.image}
                    alt=""
                  />
                </motion.div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
