import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react";
import Header from "./Base/Header";
import Footer from "./Base/Footer";
import Home from "./Home/Home";
import Agenda from "./Agenda/Agenda";
import Faq from "./Faq/Faq";

export default function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {/* <div class="body__bg">
        <img src={process.env.PUBLIC_URL + "/images/main.png"} alt="" />
      </div> */}
      <main className="main">
        <Header />

        <Routes>
          <Route path="" element={<Home />} />
          <Route path="/agenda" element={<Agenda />} />
          <Route path="/faq" element={<Faq />} />
        </Routes>
        <Footer />
        <Analytics />
      </main>
    </>
  );
}
