import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer class="footer">
      <div class="auto__container">
        <div class="footer__inner">
          <Link to="" class="footer__inner-logo">
            <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="" />
          </Link>
        </div>
      </div>
    </footer>
  );
}
