import React from "react";

export default function About() {
  return (
    <section class="about">
      <div class="auto__container">
        <div class="about__inner np">
          <div class="about__inner-image-wrapper">
            <div class="about__inner-image">
              <img
                src={process.env.PUBLIC_URL + "/images/about/4.png"}
                alt=""
              />
            </div>
          </div>
          <div class="about__inner-image-wrapper">
            <div class="about__inner-image">
              <img
                src={process.env.PUBLIC_URL + "/images/about/5.png"}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
