import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function Expect() {
  const [ref, inView] = useInView({
    triggerOnce: true, // This ensures the animation triggers only once when the element comes into view
  });
  const video = useRef(null);
  const [videoState, setVideoState] = useState(false);
  const playBtnClick = () => {
    video?.current?.play();
    setVideoState(true);
  };
  const pauseBtnClick = () => {
    video?.current?.pause();
    setVideoState(false);
  };
  //   const playBtn = document.getElementById("playBtn");
  //   if (playBtn) {
  //     const banner = document.getElementById("videoWrapper");
  //     const video = document.getElementById("video");

  //     playBtn.addEventListener("click", function () {
  //       if (video.paused == true) {
  //         video.play();

  //         playBtn.classList.toggle("active");
  //         banner.classList.toggle("active");
  //       } else {
  //         video.pause();

  //         playBtn.classList.remove("active");
  //         banner.classList.remove("active");
  //       }
  //     });

  //     video.onclick = function () {
  //       if (video.paused == true) {
  //         video.play();

  //         playBtn.classList.toggle("active");
  //         banner.classList.toggle("active");
  //       } else {
  //         video.pause();

  //         playBtn.classList.remove("active");
  //         banner.classList.remove("active");
  //       }
  //     };
  //   }
  return (
    <section className="expect">
      <div className="auto__container">
        <div className="expect__inner">
          {/* <motion.div
            ref={ref}
            initial={{ opacity: 0, x: -100 }} // Set your initial state
            animate={inView ? { opacity: 1, x: 0 } : {}} // Set your animation properties
            transition={{
              duration: 0.9,
              ease: "easeOut",
            }}
            className="expect__inner-content"
          >
            <h3>WHAT TO EXPECT</h3>
            <p>
              This invitation-only gathering offers top recruitment-industry
              leaders the opportunity to connect, collaborate, and gain valuable
              sector insights.
            </p>
            <br />
            <p className="expect-block">
              Designed with a blend of...
              <br />
              <p className="first">
                <span className="bp">•</span> Thought-provoking peer discussions
              </p>
              <p>
                <span className="bp">•</span> Engaging thought leadership
              </p>
              <p className="last">
                <span className="bp">•</span> Interactive community-building
                activities
              </p>
              ...participants will explore critical HR & TA topics while
              cultivating authentic, long-lasting industry relationships.
            </p>
          </motion.div> */}
          <motion.div
            ref={ref}
            initial={{ opacity: 0, x: 100 }} // Set your initial state
            animate={inView ? { opacity: 1, x: 0 } : {}} // Set your animation properties
            transition={{
              duration: 0.9,
              ease: "easeOut",
            }}
            className="expect__inner-banner"
          >
            <p className="vid-above-msg">Watch the 2023 Recap:</p>
            <div className={"video  " + (videoState ? "active" : "")}>
              <video
                src={process.env.PUBLIC_URL + "/images/video.mp4"}
                poster={process.env.PUBLIC_URL + "/images/main.png"}
                ref={video}
                onEnded={() => setVideoState(false)}
                controls={true}
              ></video>
              {videoState ? (
                <div className="video__play active" onClick={pauseBtnClick}>
                  <span>
                    <img
                      src={process.env.PUBLIC_URL + "/images/icons/play.svg"}
                      alt=""
                    />
                  </span>
                </div>
              ) : (
                <div className="video__play" onClick={playBtnClick}>
                  <span>
                    <img
                      src={process.env.PUBLIC_URL + "/images/icons/play.svg"}
                      alt=""
                    />
                  </span>
                </div>
              )}
            </div>
            <div className="bottom-button-container">
              <a
                href="https://cvent.me/92y57y"
                target="_blank"
                className="button primary"
              >
                REGISTER NOW
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}
