import React from "react";
import Info from "./components/Info";
import About from "./components/About";

export default function Agenda() {
  return (
    <>
      <Info />
      <About />
    </>
  );
}
