import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export default function Hero() {
  return (
    <section class="hero">
      <div class="auto__container">
        <div class="hero__inner">
          <motion.div
            animate={{
              scale: [0, 1.1, 1],
              opacity: [0, 1],
            }}
            transition={{ duration: 0.6 }}
          >
            {/* <div class="hero__inner-top">
              <div class="hero__inner-logo">
                <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="" />
              </div>
              <h1>
                LEADERSHIP <br />
                SUMMIT
                <span>2024</span>
              </h1>
            </div> */}
            <div className="header-logo">
              <img
                className="img-fluid"
                src={process.env.PUBLIC_URL + "/images/header.png"}
                alt="Zip Recruiter Leadership Summit 2024"
              />
            </div>
          </motion.div>
          <motion.div
            animate={{
              x: [-70, 0],
              opacity: [0, 1],
            }}
            transition={{ duration: 0.6 }}
          >
            <div class="hero__inner-text">
              <h4>
                April 17 – 19, 2024
                <span> | </span>
                Terranea Resort
                <span> | </span>
                Ranchos Palos Verdes, CA
              </h4>
              {/* <p class="big">
                A premier thought-leadership and community building event hosted
                by recruitment leaders, for recruitment leaders.
              </p> */}
              <p>
                Join an exclusive group of Talent Acquisition Executives at this
                must-attend VIP experience at the picturesque Terranea Resort.
              </p>
              <a
                href="https://cvent.me/92y57y"
                target="_blank"
                className="button primary"
              >
                REGISTER NOW
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}
