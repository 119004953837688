import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

export default function Header() {
  const [menu, setMenu] = useState(false);

  const close = (e) => {
    if (e.target === e.currentTarget) setMenu(false);
  };
  useEffect(() => {
    if (menu) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [menu]);
  return (
    <header class="header" id="header">
      <div class="auto__container">
        <div class="header__inner">
          <Link to="" class="header__inner-logo">
            <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="" />
          </Link>
          <nav className={"nav " + (menu ? "active" : "")} onClick={close}>
            <ul class="nav__inner">
              <li>
                <NavLink to="">HOME</NavLink>
              </li>
              <li>
                <NavLink to="/agenda">AGENDA</NavLink>
              </li>
              <li>
                <NavLink to="/faq">FAQs</NavLink>
              </li>
            </ul>
          </nav>
          <div
            className={"burger " + (menu ? "active" : "")}
            onClick={() => {
              setMenu(!menu);
            }}
          >
            <span></span>
          </div>
        </div>
      </div>
    </header>
  );
}
